/* @font-face {
  font-family: "FuturaStd";
  font-style: bold;
  font-weight: 700;
  src: url("fonts/FuturaStd-Book.otf") format("opentype");
  font-display: swap;
} */

body {
  margin: 0;
  font-family: "FuturaStd, sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #003e52 !important;
  padding-top: 5vw;
}

.react-tabs__tab-list {
  background-color: white;
  border-radius: 0.25rem !important;
  padding: 20px !important;
  white-space: nowrap;
  border-color: white !important;
}

.react-tabs__tab-panel {
  background-color: white;
  border-radius: 0.25rem !important;
  padding: 20px !important;
  overflow-y: auto;
}

.react-tabs__tab-panel .react-tabs__tab-panel {
  padding: 0 !important;
}

.react-tabs__tab:active {
  border: none !important;
}

.react-tabs__tab,
.react-tabs__tab--selected {
  background-color: white;
  border-radius: 0.25rem !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: none !important;
  color: black !important;
  white-space: nowrap;
}

.react-tabs__tab--selected {
  background-color: black !important;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: none !important;
  color: white !important;
}

.uppercase {
  text-transform: uppercase;
}

.status {
  padding: 2px 10px;
  border-radius: 50px;
  color: white;
  white-space: nowrap;
  display: inline-block;
}

@media (min-width: 1000px) {
  .statistics-wrapper {
    display: flex;
  }
}

.statistics {
  background-color: black;
  color: white;
  margin: 20px 0 !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  border-radius: 0.25rem !important;
}

@media (min-width: 1000px) {
  .statistics {
    margin: 20px !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-footer.modal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  justify-content: space-between;
  margin: 0 1rem;
  z-index: 10;
}

.table td[role="cell"] {
  vertical-align: middle;
  padding: 5px 1vw !important;
}
